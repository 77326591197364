import { Link, useLocation } from "react-router-dom";
// import { useState } from 'react';
// import { useSelector } from 'react-redux';


import { useMediaPredicate } from 'react-media-hook';
import { MOBILE_SIZE } from 'utils/mediaQueryUtils';
 
import './DashboardNav.css';

const AdminNav = ({ onChangeNav }) => {
	const location = useLocation();
	const pathname = location.pathname;
	const isMobile = useMediaPredicate(MOBILE_SIZE);
	// const [isCollapsed, setIsCollapsed] = useState(isMobile);
	const isCollapsed = isMobile;
	

	const isActive = (navItem) => {
		return pathname === navItem.url;
	}

	const navItems = [
		{
			label: "Portfolios",
			url: "/admin/portfolios",
			key: "portfolios",
			icon: "bi bi-building",
		},
		{
			label: "Top Violations",
			url: "/admin/top-violations",
			key: "topViolations",
			icon: "bi bi-graph-up",
		},
		{
			label: "Users",
			url: "/admin/users",
			key: "users",
			icon: "bi bi-people",
		},
		{
			label: "Notifications",
			url: "/admin/notifications",
			key: "notifications",
			icon: "bi bi-bell",
		},
		{
			label: "Files",
			url: "/admin/files",
			key: "files",
			icon: "bi bi-file-earmark-ruled",
		},
	];

	// let titleText = "Dashboard";

	// navItems.forEach(item => {
	// 	if (isActive(item)) {
	// 		titleText = item.label;
	// 	}
	// })

	return (
		<div className={`rd-dashboard-nav ${isCollapsed ? 'rd-dashboard-collapsed' : ''}`}>
			{/*!isCollapsed && <div className="rd-dashboard-nav-title">{titleText}</div>*/}
			<div className="rd-dashboard-nav-content">
				<div className="rd-dashboard-nav-list">
					{
						navItems.map(item => (
							<div key={item.key} className={`rd-dashboard-nav-item ${isActive(item) ? 'is-active' : ''}`}>
								<Link to={item.url} className="rd-dashboard-nav-link">
									<span className="rd-dashboard-nav-icon"><i className={item.icon} /></span>
									{!isCollapsed && <span className="rd-dashboard-nav-label">{item.label}</span>}
								</Link>
							</div>
						))
					}
				</div>
				<div className="dashboard-nav-collapse-button-wrapper" />
{/*					{!isMobile && (
						<button className="btn btn-link dashboard-nav-collapse-button" onClick={toggleCollapse}>
							{isCollapsed ? <i className="be bi-caret-right-fill" /> : <i className="be bi-caret-left-fill" />}
						</button>
					)}
				</div>*/}
			</div>
		</div>
	);
}

export default AdminNav;
