// import { Link } from "react-router-dom";
// import { useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';

import { usePageTitle } from 'utils/pageTitle';
import DashboardLayout from 'pages/_rd_dashboard/DashboardLayout';
 
// import { fetchNotifications } from 'reducers/notificationsReducer';

import AlertsTable from 'pages/_rd_dashboard/AlertsTable';
// import Spinner from 'components/Spinner';
// import Paginator from 'components/Paginator';

// import './_rd_Dashboard.css';

const Alerts = (props) => {
	usePageTitle('Alerts');
	
	// const dispatch = useDispatch();
	// let {
	// 	userId,
	// 	notifications,
	// 	isLoading,
	// 	currentPage,
	// 	totalPages,
	// } = useSelector(state => ({
	// 	userId: state.user.userData?.id,
	// 	notifications: state.notifications.notificationsData?.data,
	// 	isLoading: state.notifications.isLoading,
	// 	currentPage: state.notifications.notificationsData?.current_page,
	// 	totalPages: state.notifications.notificationsData?.last_page,
	// }));

	// useEffect(() => {
	// 	dispatch(fetchNotifications(userId));
	// }, [userId, dispatch]);

	return (
		<DashboardLayout>
			<div className="">
				<h1>Alerts</h1>
				<AlertsTable />
			</div>
		</DashboardLayout>
	);	


}

export default Alerts;
