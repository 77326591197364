import { useLocation, Link } from "react-router-dom";
// import { useState } from 'react';
// import { useSelector } from 'react-redux';

import { useMediaPredicate } from 'react-media-hook';
import { MOBILE_SIZE } from 'utils/mediaQueryUtils';

 
import './DashboardNav.css';

const DashboardNav = ({ onChangeNav, toggleCollapse, isCollapsed }) => {
	// let {
	// 	userData,
	// } = useSelector(state => ({
	// 	userData: state.user.userData,
	// }));

	const isMobile = useMediaPredicate(MOBILE_SIZE);
	// const [isCollapsed, setIsCollapsed] = useState(isMobile);
	// const isCollapsed = isMobile;

	const location = useLocation();
	const pathname = location.pathname;

	const isActive = (navItem) => {
		return pathname === navItem.url;
	}

	const navItems = [
		// {
		// 	label: "Dashboard",
		// 	url: "/dashboard",
		// 	key: "dashboard",
		// 	icon: "bi bi-grid-fill",
		// },
		{
			label: "Portfolio",
			url: "/dashboard/portfolio",
			key: "portfolio",
			icon: "bi bi-building",
		},
		{
			label: "Alerts",
			url: "/dashboard/alerts",
			key: "alerts",
			icon: "bi bi-bell",
		},
		{
			label: "Map",
			url: "/dashboard/map",
			key: "map",
			icon: "bi bi-geo-alt",
		},
		{
			label: "Compliance",
			url: "/dashboard/compliance",
			key: "compliance",
			icon: "bi bi-calendar4-week",
		},
		// {
		// 	label: "Reports",
		// 	url: "/dashboard/reports",
		// 	key: "reports",
		// 	icon: "bi bi-file-earmark-ruled",
		// },
		{
			label: "Settings",
			url: "/dashboard/settings",
			key: "settings",
			icon: "bi bi-gear",
		},
	];

	// let titleText = `Welcome, ${userData?.name}`;
	// if (!userData?.name) {
	// 	titleText = "Welcome";
	// }
	
	// let titleText = "Dashboard";
	// navItems.forEach(item => {
	// 	if (isActive(item)) {
	// 		titleText = item.label;
	// 	}
	// });

	// const toggleCollapse = () => {
	// 	setIsCollapsed(!isCollapsed);
	// };

	return (
		<div className={`rd-dashboard-nav ${(isCollapsed || isMobile) ? 'rd-dashboard-collapsed' : ''}`}>
			
			{/*!(isMobile) && (
				<div className="rd-dashboard-nav-title">
					{!isCollapsed ? (
						[
							<div key={'welcome'} className="dashboard-nav-title-row">Welcome</div>,
							(userData?.name && <div key={'name'} className="dashboard-nav-title-row">{userData?.name}</div>),
						]
					) : ''}
				</div>
			)*/}

			<div className="rd-dashboard-nav-content">
				<div className="rd-dashboard-nav-list">
					{
						navItems.map(item => (
							<div key={item.key} className={`rd-dashboard-nav-item ${isActive(item) ? 'is-active' : ''}`}>
								<Link to={item.url} className="rd-dashboard-nav-link">
									<span className="rd-dashboard-nav-icon"><i className={item.icon} /></span>
									{!(isCollapsed || isMobile) && <span className="rd-dashboard-nav-label">{item.label}</span>}
								</Link>
							</div>
						))
					}
				</div>			
				<div className="dashboard-nav-collapse-button-wrapper">
					{!isMobile && (
						<button className="btn btn-link dashboard-nav-collapse-button" onClick={toggleCollapse}>
							{isCollapsed ? <i className="be bi-caret-right-fill" /> : <i className="be bi-caret-left-fill" />}
						</button>
					)}
				</div>
			</div>

		</div>
	);	


}

export default DashboardNav;
