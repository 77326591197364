import { Link } from "react-router-dom";
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

 
import { fetchNotifications } from 'reducers/notificationsReducer';
import Spinner from 'components/Spinner';
import Paginator from 'components/Paginator';


const AlertsTable = (props) => {
	const dispatch = useDispatch();
	const { hidePaginator, rpp } = props;
	let {
		userId,
		notifications,
		isLoading,
		currentPage,
		totalPages,
	} = useSelector(state => ({
		userId: state.user.userData?.id,
		notifications: state.notifications.notificationsData?.data,
		isLoading: state.notifications.isLoading,
		currentPage: state.notifications.notificationsData?.current_page,
		totalPages: state.notifications.notificationsData?.last_page,
	}));

	useEffect(() => {
		if (userId) {
			if (rpp) {
				dispatch(fetchNotifications(userId, 'created_at|desc', 1, rpp));
			} else {
				dispatch(fetchNotifications(userId));
			}
		}
		
	}, [userId, rpp, dispatch]);

	return (
		<div>
			{isLoading ? <Spinner /> : 
				<div className="pb-4">
					<div className="dashboard-table-wrapper">
						<table className="table table-striped table-rounded rd-table">
							<thead><tr>
								<th>Property</th>
								<th>Notification</th>
								<th>Notification Type</th>
								<th>Time</th>
							</tr></thead>
							<tbody>
								{(!notifications || !notifications.length) && (
									<tr><td colSpan={5}>No alerts</td></tr>
								)}
								{notifications?.map((item) => (
									<tr key={item.id}>
										<td className="dashboard-table-property-address-cell">
											{item.property_name?.address ? (
												<Link to={`/property/${item.property_name?.address}`}>{item.property_name?.address}</Link>
											) : '-'}
										</td>
										<td>{item.notification || '-'}</td>
										<td className="dashboard-notification-type-cell">{item.notification_type?.name || '-'}</td>
										<td className="dashboard-date-time-cell">{item.created_at || '-'}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					{(totalPages > 1 && !hidePaginator) &&
						<Paginator
							currentPage={currentPage}
							totalPages={totalPages}
							setPage={(pageNum) => dispatch(fetchNotifications(userId, 'created_at|desc', pageNum, 10))}
						/>						
					}
				</div>
			}
		</div>
	);	


}

export default AlertsTable;
